import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'

export const PrivacyPageTemplate = ({
  title,
  titleEn,
  description,
  helmet,
  privacy,
}) => (
  <main className="l-main">
    <div className="l-container">
      {helmet || ''}  
      <h1 className="c-pageTitle" data-spacingsp="mb7">
        <span className="c-pageTitle__container">
          <span className="c-pageTitle__textJa">{title}</span>
          <span className="c-pageTitle__textEn">{titleEn}</span>
        </span>
      </h1>
    </div>
    <div className="l-container--wide">
      <ul className="p-breadCrumbs">
        <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
        <li className="p-breadCrumbs__item isCurrent">{title}</li>
      </ul>
    </div>
    <div className="l-container">
      <p className="u-lineHeight200 u-whiteSpacePreWrap" data-spacing="mb8 pb1" data-spacingsp="mb6 pb0">
        {description}
      </p>
    </div>
    <div className="l-container">
      <div className="p-privacy">
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy1.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy1.description}  
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy2.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy2.description}  
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy3.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy3.description}  
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy4.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy4.description}  
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy5.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy5.description}  
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy6.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy6.description}  
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy7.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy7.description}  
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy8.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy8.description}  
            <a
              href="https://marketingplatform.google.com/about/analytics/terms/jp/"
              className="u-textDecoration u-font--marineBlue"
              target="_blank"
            >
              Google アナリティクス利用規約 
            </a>
          </div>
        </section>
        <section className="p-privacy__item">
          <h2 className="p-privacy__title">{privacy.privacy9.title}</h2>
          <div className="p-privacy__text">
            {privacy.privacy9.description}  
            <a
              href="/contact"
              className="u-textDecoration u-font--marineBlue"
              target="_blank"
            >
              お問い合わせ
            </a>
          </div>
        </section>
      </div>  
    </div>
  </main>
)

PrivacyPageTemplate.propTypes = {
  heading: PropTypes.string,
  headingEn: PropTypes.string,
  title: PropTypes.string,
  titleEn: PropTypes.string,
  description: PropTypes.string,
  helmet: PropTypes.object,
}


const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PrivacyPageTemplate
        title={frontmatter.title}
        titleEn={frontmatter.titleEn}
        description={frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | 株式会社アーバ">
            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.description}`}
            />
          </Helmet>
        }
        privacy={frontmatter.privacy}
      />
    </Layout>
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PrivacyPage

export const PrivacyPageQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        titleEn
        description
        privacy{
          privacy1{
            title
            description
          }
          privacy2{
            title
            description
          }
          privacy3{
            title
            description
          }
          privacy4{
            title
            description
          }
          privacy5{
            title
            description
          }
          privacy6{
            title
            description
          }
          privacy7{
            title
            description
          }
          privacy8{
            title
            description
          }
          privacy9{
            title
            description
          }
          privacy10{
            title
            description
          }
        }
      }
    }
  }
`
